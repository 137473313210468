import BaseBean from "@/utils/BaseBean";

export interface ICustomerListDataObj {
    utilInst:CustomerListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class CustomerListUtil extends BaseBean{
    public dataObj:ICustomerListDataObj

    constructor(proxy:any,dataObj:ICustomerListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.countryData=await this.utils.OrderProviderApi.getAllCountry({});
    }
    //查询客户的所有账号
    public async getCustomerAccount(customerId:string):Promise<any>{
        //查询指定客户的所有账号集合
        this.dataObj.otherParams.customerAccountData=await this.utils.OrderProviderApi.getCustomerAccountByCustomerId({params:{customerId:customerId,type:-1}});
        this.dataObj.otherParams.customerAccountData=this.dataObj.otherParams.customerAccountData.map((item:any)=>{
            return {id:item.value,code:item.label,type:item.item.type==0?'整车':'售后'}
        })
    }
}